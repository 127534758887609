import { PropsWithChildren, useContext, useState } from 'react'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import { useTheme } from '@mui/material/styles'
// types
import { ILXTeaser, ITeaserOverlaySettings, ITeaserOverlayStyle } from '@typesApp/cmsPlacement/LXTeaser'
import { IPlacement, IPlacementItem, isCMCollection, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { CmsIcon } from '@components/Cms/CmsComponents-CSS/CmsIcon/CmsIcon'
import { CmsCountdown } from '@components/Cms/CmsComponents-CSS/CmsCountdown'
import { CmsCta } from '../CmsCta'
import { TermsAndConditions } from '../TermsAndConditions'
import { ConditionalWrapper } from '../Media/ConditionalWrapper'
import { CmsProductTile } from '../CmsProductTile/CmsProductTile'
import { ContextWrapperData, PlacementContext } from '../PlacementContextWrapper/PlacementContextWrapper'
import styles from './styles/index.module.scss'
import productTileOverrideStyles from './styles/productTileOverride.module.scss'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { ProductContextProvider } from '@components/PagesSeo/product/context/ProductContext'
import { trimText } from '@utils/common'
// TODO: cleanup the mess with colours

type ContextType = {
  index: number
  placement: IPlacement | ICMCollection
  teaserIndex: number
}

type TextModuleBannerProps = {
  className?: string
  hideTermsAndConditions?: boolean
  layout?: 'column-1' | 'column-2'
  overrideTeaserOverlaySettings?: ITeaserOverlaySettings
  overrideTeaserOverlayStyle?: ITeaserOverlayStyle
  overrideTeaserOverlayTextAlign?: 'left' | 'center' | 'right'
  resetGap?: boolean
  showCmsIcon?: boolean
  showCtas?: boolean
  termsAndConditionsClassName?: string
}

const viewTypeWithCustomTextStyles = ['box-and-2-products', 'box-and-4-products']

export const TextModuleBanner: React.FC<PropsWithChildren<TextModuleBannerProps>> = props => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data } = context as ContextWrapperData<ContextType>
  const { viewtype } = data?.placement ?? {}
  const theme = useTheme()

  const {
    children,
    className,
    layout = 'column-1',
    hideTermsAndConditions = false,
    resetGap,
    overrideTeaserOverlaySettings,
    overrideTeaserOverlayStyle,
    overrideTeaserOverlayTextAlign,
    showCmsIcon = true,
    showCtas = true,
    termsAndConditionsClassName,
  } = props

  let item = (
    data?.placement && 'items' in data?.placement ? data?.placement?.items?.find(isLXTeaser) : {}
  ) as ILXTeaser

  if (isCMCollection(data?.placement as IPlacementItem)) {
    item = (data?.placement as ICMCollection)?.teasableItems?.find(isLXTeaser) as ILXTeaser
  }

  const [currentProductIndex, setCurrentProductIndex] = useState(0)

  if (!item) return null

  const {
    promoteToH1,
    teaserPreTitle,
    teaserTitle1,
    teaserTitle4,
    teaserText1,
    teaserText2,
    teaserOverlay1Settings,
    teaserOverlay2Style,
    teaserOverlay2TextAlign,
    teaserLXCallToActionSettings,
    teaserIcon,
    relatedProduct,
  } = item

  const slidePrev = () => {
    setCurrentProductIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : (relatedProduct?.length || 1) - 1))
  }

  const slideNext = () => {
    setCurrentProductIndex(prevIndex => (prevIndex < (relatedProduct?.length || 1) - 1 ? prevIndex + 1 : 0))
  }

  return (
    <section
      className={clsx(
        'cms-section',
        'text-module',
        !viewTypeWithCustomTextStyles.includes(viewtype || '') && styles['text-module'],
        overrideTeaserOverlaySettings ?? teaserOverlay1Settings,
        overrideTeaserOverlayStyle ?? teaserOverlay2Style,
        overrideTeaserOverlayTextAlign ?? teaserOverlay2TextAlign,
        {
          'reset-gap': resetGap,
          'column-2': layout === 'column-2',
        },
        className
      )}
    >
      <div className={styles['text-module-inner']}>
        {teaserIcon && showCmsIcon && <CmsIcon teaserIcon={teaserIcon} />}
        <CmsCountdown teaser={item} />

        {teaserPreTitle && <div className={styles['teaser-pretitle']}>{teaserPreTitle}</div>}
        {(teaserTitle1 || teaserTitle4) && (
          <ConditionalWrapper
            key={`teaser-title-${uuid()}`}
            condition={true}
            wrapperTag={promoteToH1 ? 'h1' : 'h2'}
            className={clsx(styles['teaser-title'], {
              h1: promoteToH1,
              h2: !promoteToH1,
            })}
          >
            {teaserTitle1 || teaserTitle4}
          </ConditionalWrapper>
        )}

        {(teaserText1 || teaserText2) && (
          <div
            key={`teaser-text-${uuid()}`}
            className={styles['teaser-text']}
            dangerouslySetInnerHTML={{ __html: teaserText1 || teaserText2 }}
          />
        )}
      </div>
      {Array.isArray(relatedProduct) && relatedProduct.length > 0 && (
        <div className={styles.productContainer}>
          <div className={styles.iconContainer}>
            <SVGIcon
              library="arrow"
              name="arrow-left"
              size={24}
              color={theme.palette.text.dark.primary}
              onClick={slidePrev}
            />
          </div>
          <ProductContextProvider
            key={currentProductIndex}
            productData={{ product: relatedProduct[currentProductIndex].productData }}
          >
            <CmsProductTile styleOverrides={productTileOverrideStyles} />
          </ProductContextProvider>
          <div className={styles.iconContainer}>
            <SVGIcon
              library="arrow"
              name="arrow-right"
              size={24}
              color={theme.palette.text.dark.primary}
              onClick={slideNext}
            />
          </div>
        </div>
      )}

      {children}

      {showCtas && Array.isArray(teaserLXCallToActionSettings) && teaserLXCallToActionSettings.length > 0 && (
        <div className="cta-container">
          {teaserLXCallToActionSettings.map((teaser, teaserIndex) => {
            if (teaserIndex > 1) return null /* display two CTAs max */
            return (
              <CmsCta
                key={`${teaser.callToActionText}-${uuid()}`}
                teaserCtaSetting={teaser}
                dataElementId={`X_X_${data?.index ?? '0'}Placement_Banner${teaserIndex}_CTA${
                  teaserLXCallToActionSettings.length === 1 ? '' : data?.index
                }`}
                dataDescription={trimText(teaser.callToActionText ?? '')}
              />
            )
          })}
        </div>
      )}

      {!hideTermsAndConditions && <TermsAndConditions className={termsAndConditionsClassName} teaser={item} />}
    </section>
  )
}
