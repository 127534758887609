import { CMS_MODULES as CM } from '@components/Cms/constants'
import { ILXTeaser, ITeaserBackGround } from '@typesApp/cmsPlacement/LXTeaser'
import { IPlacement, IPlacementItem } from '@typesApp/cmsPlacement/Placement'

export type TeaserBackgroundColor = ITeaserBackGround | React.CSSProperties['backgroundColor']

export const isTeaserBackgroundColor = (backgroundColor: TeaserBackgroundColor) => backgroundColor?.startsWith('bg-')

type GetPlacementBackgroundColorParams = {
  placement: IPlacement<IPlacementItem>
  teaser: IPlacementItem
}

export const getPlacementBackgroundColor = ({
  placement,
  teaser,
}: GetPlacementBackgroundColorParams): TeaserBackgroundColor => {
  const excludedModules = [CM.BOX_AND_2_PRODUCTS, CM.BOX_AND_4_PRODUCTS]

  const placementBgColor = placement?.backgroundColor
  const teaserBgColor = (teaser as ILXTeaser)?.teaserBackground
  const isProductBanner = [CM.DCW_PRODUCTS].includes(teaser?.type ?? '')
  const backgroundColor = isProductBanner ? 'bg-light-secondary' : placementBgColor || teaserBgColor

  const hasBackgroundColor =
    backgroundColor !== '' &&
    isTeaserBackgroundColor(backgroundColor) &&
    !excludedModules.includes(placement.viewtype ?? '')

  return hasBackgroundColor ? backgroundColor : 'transparent'
}
